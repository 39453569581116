import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHomeAlt,
  faTools,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";

const NavbarMobile = () => {
  return (
    <div className="flex sm:hidden sticky bottom-0 bg-black text-white  text-2xl divide-x-2 divide-gray-dark">
      <Link to={`/`} className="flex-grow flex-shrink-0 w-4/12">
        <div className="flex justify-center items-center gap-x-2 py-3">
          <FontAwesomeIcon
            icon={faHomeAlt}
            className="text-gray text-xl sm:text-2xl ml-1 sm:ml-2"
          />
          หน้าหลัก
        </div>
      </Link>
      <Link to={`/repair`} className="flex-grow flex-shrink-0 w-4/12">
        <div className="flex justify-center items-center gap-x-2 py-3">
          <FontAwesomeIcon
            icon={faTools}
            className="text-gray text-xl sm:text-2xl ml-1 sm:ml-2"
          />
          แจ้งซ่อม
        </div>
      </Link>
      <Link to={`/history`} className="flex-grow flex-shrink-0 w-4/12">
        <div className="flex justify-center items-center gap-x-2 py-3">
          <FontAwesomeIcon
            icon={faHistory}
            className="text-gray text-xl sm:text-2xl ml-1 sm:ml-2"
          />
          ประวัติ
        </div>
      </Link>
    </div>
  );
};

export default NavbarMobile;
