import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiAuth extends Component {
    static login = async (data) => {
        const result = await axios({
            url: "Auth/Login",
            method: "post",
            data: data,
        });
        return result;
    };
}

export default ApiAuth;
