import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { removeLocalStorage } from "../utils/localStorage";

const Header = ({ sidebar, setSidebar }) => {
  const history = useHistory();
  const userState = useSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const signOut = async () => {
    dispatch({
      type: 'clear_all',
    });
    await removeLocalStorage('token');
    setTimeout(() => {
      history.push("/signin")
    }, 200);
  }

  return (
    <>
      <div className="h-20 bg-white hidden sm:flex justify-between items-center px-12">
        <div className="flex flex-1">
          <span className="text-xl font-semibold">สายด่วน Family :</span>
          <span className="text-3xl font-bold">1797</span>
        </div>
        <div className="flex flex-1 justify-center">
          <Link to="/">
            <div
              className="bg-center bg-contain bg-no-repeat h-10 w-40 cursor-pointer"
              style={{
                backgroundImage: `url("${process.env.PUBLIC_URL}/images/logo.png")`,
              }}
            ></div>
          </Link>
        </div>
        <div className="flex flex-1 justify-end gap-x-3">
          {
            userState?.userName ?
              <span className="text-xl font-semibold">
                {userState?.firstname + " " + userState?.lastname}
              </span>
              : <Link to="signin">
                <span className="text-xl font-semibold cursor-pointer">
                  เข้าสู่ระบบ
                </span>
              </Link>
          }
          {
            userState?.userName &&
            <button onClick={signOut} className="text-xl text-red-600 font-semibold">
              ออกจากระบบ
            </button>
          }
        </div>
      </div>
      <div className="h-12 gap-4 sm:gap-10 md:gap-14 lg:gap-20 hidden bg-black sm:flex justify-center items-center px-12">
        {userState.is_verify &&
          <div className="flex ">
            <Link to="/repair">
              <span
                className={`text-xl ${location.pathname === "/repair"
                  ? "text-[#b1ded3]"
                  : "text-white"
                  }`}
              >
                แจ้งซ่อม
              </span>
            </Link>
          </div>
        }

        <div className="flex ">
          <Link to="/repairUtils">
            <span
              className={`text-xl ${location.pathname === "/repairUtils"
                ? "text-[#b1ded3]"
                : "text-white"
                }`}
            >
              แจ้งซ่อมสาธารณูปโภค
            </span>
          </Link>
        </div>
        <div className="flex">
          <Link to="/complain">
            <span
              className={`text-xl ${location.pathname === "/complain"
                ? "text-[#b1ded3]"
                : "text-white"
                }`}
            >
              แจ้งเรื่องร้องเรียน
            </span>
          </Link>
        </div>
        <div className="flex">
          <Link to="/suggestion">
            <span
              className={`text-xl ${location.pathname === "/suggestion"
                ? "text-[#b1ded3]"
                : "text-white"
                }`}
            >
              ข้อเสนอแนะ
            </span>
          </Link>
        </div>
        <div className="flex">
          <Link to="/history">
            <span
              className={`text-xl ${location.pathname === "/history"
                ? "text-[#b1ded3]"
                : "text-white"
                }`}
            >
              ประวัติรายการ
            </span>
          </Link>
        </div>
        {/* <div className="flex">
          <span className="text-xl text-white">ข่าวสาร</span>
        </div>
        <div className="flex">
          <span className="text-xl text-white">ติดต่อเรา</span>
        </div> */}
      </div>
      <div className="bg-white flex flex-col sm:hidden px-4 py-4">
        <div className="flex justify-between items-center mt-4">
          <div onClick={() => setSidebar(!sidebar)}>
            <FontAwesomeIcon
              icon={faBars}
              size="2x"
              className="text-black"
            />
          </div>
          <div
            className="bg-center bg-contain bg-no-repeat self-center h-12 w-40"
            style={{
              backgroundImage: `url("${process.env.PUBLIC_URL}/images/logo.png")`,
            }}
          ></div>
          <Link to="signin">
            <div className="flex">
              <span className="text-xl font-semibold">เข้าสู่ระบบ</span>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
