import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiTransaction extends Component {
    static insert = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: "TranSaction",
            method: "post",
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return result;
    };

    static getTaskWeeks = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Weeks`,
            method: "post",
            data: data
        });
        return result;
    };

    static update = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction`,
            method: "put",
            data: data
        });
        return result;
    };
    static updateRating = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/Rating`,
            method: "post",
            data: data
        });
        return result;
    };
    static getRef = async (ref) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/${ref}`,
            method: "get",
        });
        return result;
    };

    static getHistory = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranSaction/History`,
            method: "get",
        });
        return result;
    };
}

export default ApiTransaction;
