import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePen,
  faCalendarDay,
  faCircleChevronRight,
  faCirclePlus,
  faCircleChevronLeft,
  faCircle,
  faLaptopHouse
} from "@fortawesome/free-solid-svg-icons";
import CSwlAlert from "../../utils/alert";
import ApiCatalog from "../../api/ApiCatalog";
import moment from "moment";
import { ConvertDate, getWeeks } from "../../utils";
import ApiConfig from "../../api/ApiConfig";
import ApiTransaction from "../../api/ApiTransaction";
import _ from 'lodash';
const RepairDetailUtils = () => {
  const [page, setpage] = useState('detail');
  const history = useHistory();
  const [dateNow, setdateNow] = useState(moment());
  const [dateList, setdateList] = useState([]);
  const [holidayList, setholidayList] = useState([]);
  const [dateSelected, setdateSelected] = useState();
  const [periodSelected, setperiodSelected] = useState();
  const [catalogs, setCatalogs] = useState([]);
  const [subcatalogs, setSubCatalogs] = useState([]);
  const location = useLocation();
  const [config, setConfig] = useState("");
  const typeRepair = new URLSearchParams(location.search).get("request")
  const [repair, setRepair] = useState('');
  const [listRepair, setListRepair] = useState([]);
  const [request, setRequest] = useState("");
  const [orderHistory, setorderHistory] = useState([]);
  const [step, setstep] = useState(1);

  useEffect(() => {
    const weeks = getWeeks(dateNow.toDate());
    getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
    getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
    setdateList(weeks);
  }, []);

  useEffect(() => {
    CSwlAlert.SwalLoad();
    const promise1 = getConfigs();
    Promise.all([promise1]).then((result) => {
      CSwlAlert.SwalClose();
    })
  }, []);



  const getConfigs = async () => {
    const response = await ApiConfig.getConfigByCode('busy_date');
    if (response.status == 200) {
      const { data } = response.data;
      console.log('config', data);
      setConfig(data);
    }
  }

  const getTransactionWeeks = async ({ dateFrom, dateTo, type = "load" }) => {
    if (type == "load") {
      CSwlAlert.SwalLoad();
    }
    const response = await ApiTransaction.getTaskWeeks({ dateFrom, dateTo });
    if (response.status == 200) {
      if (type == "load") {
        CSwlAlert.SwalClose();
      }
      const { data } = response.data;
      const groupItems = _(data)
        .groupBy('maRef')
        .map((items, key) => ({
          main: key,
          date: items[0].dateTime,
          period: items[0].period,
          zone: items[0].zone,
          status: items[0].status,
          items: items,
        }))
        .value();
      console.log('groupItems', groupItems);
      setorderHistory(groupItems);
    }
  }



  const getHoliday = async ({ dateFrom, dateTo, type = "load" }) => {
    if (type == "load") {
      CSwlAlert.SwalLoad();
    }
    const response = await ApiConfig.getHoliday({ dateFrom, dateTo });
    if (response.status == 200) {
      if (type == "load") {
        CSwlAlert.SwalClose();
      }
      const { data } = response.data;
      setholidayList(data);
    }
  }


  const addList = () => {
    setListRepair([
      ...listRepair,
      {
        repair: typeRepair,
        is_verify: false,
        is_urgent: false,
        jobType: "",
        detail: "",
        picture: [],
        video: [],
      },
    ]);
  };

  useEffect(() => {
    CSwlAlert.SwalLoad();
    const promise1 = getCatalogs();
    const promise2 = getSubCatalogs();
    Promise.all([promise1, promise2]).then((result) => {
      CSwlAlert.SwalClose();
    })
  }, []);

  const getCatalogs = async () => {
    const response = await ApiCatalog.getCatalogUtils();
    if (response.status == 200) {
      const { data } = response.data;
      setCatalogs(data);
      const repair = data.find(x => x.id == typeRepair);
      setRepair(repair.catalogname)
      setListRepair([
        {
          is_verify: false,
          is_urgent: false,
          repair: typeRepair,
          jobType: "",
          detail: "",
          picture: [],
          video: [],
        },
      ]);
    }
  }
  const getSubCatalogs = async () => {
    const response = await ApiCatalog.getSubCatalog();
    if (response.status == 200) {
      const { data } = response.data;
      setSubCatalogs(data);
    }
  }

  const confirmAppointment = async (e) => {
    CSwlAlert.SwalLoad();
    e.preventDefault();
    if (!dateSelected) {
      CSwlAlert.SwalErr({ description: "กรุณาระบุวันที่ทำการนัดหมาย" });
      return false;
    }
    const formData = new FormData();
    const pictures = [];
    await Promise.all(listRepair.map((item, index) => {
      item.picture.forEach(element => {
        element.name_ref = `.ref-${index}`;
        pictures.push(element);
      });
      item.appointmentDate = dateSelected;
      item.period = periodSelected;
      item.imageCount = item.picture.length
      // formDataItem.append('repair', item.repair);
      // formData.append('req', formDataItem);
    }))
    formData.append('transactionItem', JSON.stringify(listRepair));
    await Promise.all(pictures.map((item, index) => {
      formData.append('images', item);
    }))
    try {
      const result = await ApiTransaction.insert(formData);
      if (result.status == 200) {
        const { tranRef } = result.data;
        CSwlAlert.SwalClose();
        history.push(`/repair-detail-summary?request=${tranRef}`)
      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message })
    }
  }

  const confirmAppointmentNoCalendar = async () => {
    CSwlAlert.SwalLoad();
    const formData = new FormData();
    const pictures = [];
    await Promise.all(listRepair.map((item, index) => {
      item.picture.forEach(element => {
        element.name_ref = `.ref-${index}`;
        pictures.push(element);
      });
      item.appointmentDate = dateSelected;
      item.period = periodSelected;
      item.imageCount = item.picture.length
      // formDataItem.append('repair', item.repair);
      // formData.append('req', formDataItem);
    }))
    formData.append('transactionItem', JSON.stringify(listRepair));
    await Promise.all(pictures.map((item, index) => {
      formData.append('images', item);
    }))
    try {
      const result = await ApiTransaction.insert(formData);
      if (result.status == 200) {
        const { tranRef } = result.data;
        CSwlAlert.SwalClose();
        history.push(`/repair-detail-summary?request=${tranRef}`)
      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message })
    }
  }

  const uploadFile = async (e, index) => {
    if (e.target.files[0]) {
      let arr = [...listRepair];
      arr[index].picture.push(e.target.files[0]);
      setListRepair([...arr]);
    }
  }

  const checkDate = (date, period) => {
    const timeNow = moment().format('HH');

    if (period == 1 && timeNow >= 12 && moment(date).format("DD/MM/YYYY") == moment().add('days', 1).format("DD/MM/YYYY")) {
      return true;
    }
    const nowDate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
    const pickDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const listHoliday = holidayList.filter(x => x.period == 4).map(x => new Date(x.bookdate).toDateString())
    const listHolidayFM = holidayList.filter(x => x.period != 4).map((x) => {
      return new Date(x.bookdate).toDateString() + "_" + x.period
    });
    if (pickDate <= nowDate) {
      return true;
    }
    if (config?.value && config?.value.split(',').includes(ConvertDate(date, 'ddd'))) {
      return true;
    } else if (listHoliday.includes(date.toDateString())) {
      return true;
    }
    else if (listHolidayFM.length > 0
      && listHolidayFM.includes(date.toDateString() + "_" + period)
    ) {
      return true;
    }
    else if (orderHistory.filter(x => x.period == period).map(x => new Date(x.date).toDateString()).includes(date.toDateString())) {
      return true;
    }
    else {
      return false;
    }
  }

  const checkDateText = (date, period) => {
    if (orderHistory.filter(x => new Date(x.date).toDateString() == date.toDateString() && x.period == period)?.length > 0) {
      return `แจ้งซ่อม </br>
      ${orderHistory.find(x => new Date(x.date).toDateString() == date.toDateString() && x.period == period)?.zone}`
    } else {
      return `ว่าง`;
    }
  }

  return (
    <div>
      <div className="bg-white w-100 hidden sm:flex items-center flex-col py-4">
        <span className="text-3xl">{repair}</span>
        <div className="gap-4 flex">
          <Link to="/">
            <span className="text-xl">หน้าหลัก</span>
          </Link>
          <span className="text-xl">•</span>
          <Link to="/repair">
            <span className="text-xl">แจ้งซ่อม</span>
          </Link>
          <span className="text-xl">•</span>
          <span className="text-xl">{repair}</span>
        </div>
      </div>
      <div className="container max-w-3xl sm:mx-auto py-10">
        <div className="flex justify-center items-center pb-[36px] sm:mx-0 gap-x-3 sm:gap-x-9">
          <div onClick={() => setstep(1)} className="flex justify-center items-center gap-x-3 py-2 rounded-full bg-[#6fd4bb] w-[140px] sm:w-[180px]">
            <div className="flex justify-center items-center bg-white rounded-full w-[30px] h-[30px] sm:w-[55px] sm:h-[55px]">
              <FontAwesomeIcon
                icon={faFilePen}
                className="text-[#6fd4bb] text-xl sm:text-2xl ml-1 sm:ml-2"
              />
            </div>
            <div className="mr-2">
              <span className="sm:text-2xl">STEP 1</span>
              <div className="text-sm sm:text-lg">กรอกรายละเอียด</div>
            </div>
          </div>
          <div className="cursor-pointer flex-shrink-0 flex justify-center items-center w-[26px] h-[26px]">
            <FontAwesomeIcon
              icon={faCircleChevronRight}
              className="text-[#D4D4D5] bg-[#999999] w-full h-full rounded-full"
            />
          </div>
          {/* <Link to={`/repair-detail-2?request=${request}`}> */}
          <div className={`cursor-pointer flex justify-center items-center gap-x-3 py-2 rounded-full w-[140px] sm:w-[180px] ${step == 1 ? 'bg-[#D4D4D5]' : 'bg-[#6fd4bb]'}`}>
            <div className="flex justify-center items-center bg-white rounded-full w-[30px] h-[30px] sm:w-[55px] sm:h-[55px]">
              <FontAwesomeIcon
                icon={faCalendarDay}
                className={`${step == 1 ? 'text-[#999999]' : 'text-[#6fd4bb]'} text-xl sm:text-2xl`}
              />
            </div>
            <div className="mr-2">
              <div className={`${step == 1 ? 'text-[#999999]' : ''} sm:text-2xl`}>STEP 2</div>
              <div className="text-sm sm:text-lg">เลือกวัน / เวลา</div>
            </div>
          </div>
          {/* </Link> */}
        </div>
        {step == 1 && <form onSubmit={(e) => {
          e.preventDefault();
          confirmAppointmentNoCalendar();
        }}>
          {listRepair.map((item, index) => {
            return (
              <section key={`${item.repair}-${index}`}>
                <div
                  className="bg-white rounded-lg mb-9 mx-3 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]"
                  key={`${item.repair}-${index}`}
                >
                  <div className="px-5 sm:px-10 py-8">
                    <div className="flex items-baseline">
                      <div className="text-2xl sm:text-3xl mb-2 sm:mb-0 mr-3 sm:mr-10">
                        รายการที่ {index + 1}
                      </div>
                      {index === 0 ? (
                        <div className="flex-grow flex items-center px-5 text-2xl border-[1px] border-[#797879] border-dashed rounded-lg bg-[#e8e8e9]">
                          {catalogs.find(x => x.id == typeRepair)?.catalogname}
                        </div>
                      ) : (
                        <select
                          className="form-control text-xl flex-grow"
                          value={item.repair}
                          onChange={(e) => {
                            let arr = [...listRepair];
                            arr[index].repair = e.target.value;
                            arr[index].jobType = "";
                            setListRepair([...arr]);
                          }}
                        >
                          {catalogs.map((item, index) =>
                            <option key={"cata-" + item.id} value={item.id}>{item.catalogname}</option>
                          )}
                        </select>
                      )}
                    </div>
                    <div className="flex flex-col mt-9">
                      <span className="text-xl required">ประเภทงาน</span>
                      <select
                        className="form-control text-xl"
                        value={item.jobType}
                        onChange={(e) => {
                          let arr = [...listRepair];
                          arr[index].jobType = e.target.value;
                          arr[index].is_urgent = subcatalogs.find(x => x.id == e.target.value).isUrgent;
                          setListRepair([...arr]);
                        }}
                        required
                        placeholder="กรุณาเลือกประเภทงาน"
                      >
                        <option value="">กรุณาเลือกประเภทงาน</option>
                        {subcatalogs.filter(x => x.catalogId == item.repair).map((item, index) =>
                          <option key={"subcat-" + item.id} value={item.id}>{item.subcatalogName}</option>
                        )}
                      </select>
                    </div>
                    <div className="flex flex-col mt-9">
                      <span className="text-xl">รายละเอียด</span>
                      <input
                        placeholder="กรุณากรอกรายละเอียด"
                        className="form-control text-xl"
                        value={item.detail}
                        onChange={(e) => {
                          let arr = [...listRepair];
                          arr[index].detail = e.target.value;
                          setListRepair([...arr]);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mt-9 px-5 sm:px-10 py-8">
                    <span className="text-xl">ภาพประกอบ</span>
                    <div className="flex items-center gap-x-3 flex-wrap">
                      {item.picture.length > 0 &&
                        item.picture.map((pic, index) => {
                          return (
                            <div key={`picture-${index}`} className="flex-shrink-0 flex justify-center items-center w-44 sm:w-52 mt-3 h-[152px]">
                              <img
                                src={URL.createObjectURL(pic)}
                                alt={`pic-${index}`}
                                className="border-2 border-gray-light border-dashed w-full h-full rounded-lg"
                              />
                            </div>
                          );
                        })}
                      <div className="flex-shrink-0 flex justify-center items-center w-44 sm:w-52 mt-3">
                        <label
                          htmlFor={"dropzone-file-client-" + index}
                          className="flex flex-col justify-center items-center w-full p-5 bg-gray-50 rounded-lg border-2 border-gray-light border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                        >
                          <div className="flex flex-col justify-center items-center">
                            <svg
                              aria-hidden="true"
                              className="mb-3 w-10 h-10 text-gray-light"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray dark:text-gray">
                              <span className="font-semibold">
                                Click to upload
                              </span>
                            </p>
                            <p className="mb-2 text-sm text-gray dark:text-gray">
                              or drag and drop
                            </p>
                          </div>
                          <input
                            id={"dropzone-file-client-" + index}
                            type="file"
                            className="hidden"
                            name={"dropzone-file-client-" + index}
                            accept="image/png, image/jpeg"
                            onChange={(e) => {
                              uploadFile(e, index);
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="flex flex-col mt-9 px-5 sm:px-10 py-8">
                    <span className="text-xl">วิดีโอประกอบ</span>
                    <div className="flex items-center gap-x-3 overflow-x-auto">
                      {item.video.length > 0 &&
                        item.video.map((pic, index) => {
                          return (
                            <div key={`video-${index}`} className="flex-shrink-0 flex justify-center items-center w-44 sm:w-52 mt-3 h-[150px] sm:h-[152px]">
                              <img
                                src=""
                                alt={`vdo-${index}`}
                                className="border-2 border-gray-light border-dashed w-full h-full rounded-lg"
                              />
                            </div>
                          );
                        })}
                      <div className="flex-shrink-0 flex justify-center items-center w-44 sm:w-52 mt-3">
                        <label
                          htmlFor="dropzone-file"
                          className="flex flex-col justify-center items-center w-full p-5 bg-gray-50 rounded-lg border-2 border-gray-light border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                        >
                          <div className="flex flex-col justify-center items-center">
                            <svg
                              aria-hidden="true"
                              className="mb-3 w-10 h-10 text-gray-light"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray dark:text-gray">
                              <span className="font-semibold">
                                Click to upload
                              </span>
                            </p>
                            <p className="mb-2 text-sm text-gray dark:text-gray">
                              or drag and drop
                            </p>
                          </div>
                          <input
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                            onChange={(e) => {
                              let arr = [...listRepair];
                              arr[index].video.push(e.target.files);
                              setListRepair([...arr]);
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div> */}
                </div>
              </section>
            );
          })}
          <div className="mt-5 mb-12 flex justify-between gap-x-3 mx-3">
            <div
              className="btn-green-square text-lg flex justify-between items-center w-44 sm:w-auto"
              onClick={addList}
            >
              <div className="px-1 sm:px-3 ">
                <div className="flex justify-center items-center bg-[#474747] rounded-full">
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="text-[#9A9A9A]"
                  />
                </div>
              </div>
              <div className="flex-grow flex justify-center items-center px-2 h-full">
                เพิ่มรายการแจ้งซ่อม
              </div>
            </div>
            <div className="w-44 sm:w-auto">
              <button type="submit" className="btn-black-square text-lg flex justify-between items-center">
                <div className="flex-grow flex justify-center px-2">
                  ไม่มีรายการแจ้งซ่อมแล้ว
                </div>
                <div className="px-1 sm:px-3 py-3.5 flex items-center">
                  <div className="flex justify-center items-center bg-[#474747] rounded-full">
                    <FontAwesomeIcon
                      icon={faCircleChevronRight}
                      className="text-[#9A9A9A]"
                    />
                  </div>
                </div>
              </button>
            </div>
          </div>
        </form>}
        {step == 2 && <form onSubmit={confirmAppointment}>
          <div className="bg-white rounded-lg mb-9 mx-2 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
            <div className="px-5 sm:px-12 pt-6 pb-8">
              <div className="flex justify-between items-center mb-7">
                <div onClick={() => {
                  let newDate = moment();
                  setdateNow(newDate);
                  const weeks = getWeeks(newDate.toDate());
                  setdateList(weeks);
                  getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
                  getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                }} className="flex-shrink-0 px-2 sm:px-5 py-1 text-2xl sm:text-3xl bg-[#d3d3d4] rounded-lg ">
                  Today
                </div>
                <div className="flex-shrink-0 text-2xl sm:text-3xl font-bold mt-3 ml-5 sm:ml-12">
                  {
                    dateList.length > 0 && moment(dateList[0]).format('MMM YYYY')
                  }
                </div>
                <div className="flex-shrink-0 flex gap-x-2 sm:gap-x-5">
                  <div onClick={() => {
                    let newDate = dateNow;
                    newDate.add(-7, 'days');
                    setdateNow(newDate);
                    const weeks = getWeeks(newDate.toDate());
                    getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
                    setdateList(weeks);
                    getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                  }} className="flex justify-center items-center py-2 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                    <FontAwesomeIcon
                      icon={faCircleChevronLeft}
                      className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                    />
                  </div>
                  <div onClick={() => {
                    let newDate = dateNow;
                    newDate.add(7, 'days');
                    setdateNow(newDate);
                    const weeks = getWeeks(newDate.toDate());
                    getTransactionWeeks({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1], type: "firstload" })
                    setdateList(weeks);
                    getHoliday({ dateFrom: weeks[0], dateTo: weeks[weeks.length - 1] })
                  }} className="flex justify-center items-center py-1 px-4 sm:px-6 border-2 border-[#dfdede] rounded-lg">
                    <FontAwesomeIcon
                      icon={faCircleChevronRight}
                      className="text-[#D4D4D5] bg-[#999999] sm:w-[30px] sm:h-[30px] rounded-full"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex">
                  <div className="w-24 border border-[#dfdede]"></div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      return <div className="border flex flex-col flex-center border-[#dfdede] leading-3">
                        <span className="text-xl ">{ConvertDate(item, 'ddd')}</span>
                        <span className="text-2xl font-bold">{ConvertDate(item, 'DD')}</span>
                      </div>
                    })}

                  </div>
                </div>
                <div className="flex h-10">
                  <div className="w-24 h-10 flex-center items-center border border-[#dfdede]">09:00-12:00 น.</div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      return <div onClick={() => {
                        if (checkDate(item, 1)) {

                        } else {
                          console.log('item::', item)
                          setdateSelected(item);
                          setperiodSelected(1);
                        }
                      }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 1) ? "bg-[#e7e9ea]" : ""}
                      ${dateSelected == item && periodSelected == 1 && "bg-[#000] text-white"}`}>
                        {!(checkDate(item, 1)) &&
                          <div className="flex-center gap-x-1">
                            <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />
                            <span className="">ว่าง</span>
                          </div>
                        }
                      </div>
                    })}
                    {/* {dateList.map((item, index) => {
                      return <div onClick={() => {

                        if (checkDate(item, 1)) {

                        } else {
                          if (checkDateText(item, 1) !== "ว่าง") {
                            setdateSelected(item);
                            setperiodSelected(1);
                             setOrderSelected(getOrderHistorySelected(item, 1));
                          }
                        }
                      }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 1) ? "bg-[#e7e9ea]" : ""}
                      ${dateSelected == item && periodSelected == 1 && "bg-[#000] text-white"}`}>
                        {!(checkDate(item, 1)) &&
                          <div className="flex-center gap-x-1 py-4 px-2">
                            {checkDateText(item, 1) == "ว่าง" && <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />}
                            <span className="" dangerouslySetInnerHTML={{ __html: checkDateText(item, 1) }}></span>
                          </div>
                        }
                      </div>
                    })} */}
                  </div>
                </div>
                <div className="flex h-10">
                  <div className="w-24 h-10 flex-center items-center border border-[#dfdede]">13:00-17:00 น.</div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      return <div onClick={() => {
                        if (checkDate(item, 2)) {
                        } else {
                          setdateSelected(item);
                          setperiodSelected(2);
                        }
                      }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 2) ? "bg-[#e7e9ea]" : ""}
                      ${dateSelected == item && periodSelected == 2 && "bg-[#000] text-white"}`}>
                        {!(checkDate(item, 2)) &&
                          <div className="flex-center gap-x-1">
                            <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />
                            <span className="">ว่าง</span>
                          </div>
                        }
                      </div>
                    })}
                  </div>
                </div>
                <div className="flex h-10">
                  <div className="w-24 h-10 flex-center items-center border border-[#dfdede]">เวลาใดก็ได้</div>
                  <div className="flex-1 grid grid-cols-7">
                    {dateList.map((item, index) => {
                      return <div onClick={() => {
                        if (checkDate(item, 3)) {
                        } else {
                          setdateSelected(item);
                          setperiodSelected(3);
                        }
                      }} className={`border flex flex-col flex-center border-[#dfdede] leading-3 
                      ${checkDate(item, 3) ? "bg-[#e7e9ea]" : ""}
                      ${dateSelected == item && periodSelected == 3 && "bg-[#000] text-white"}`}>
                        {!(checkDate(item, 3)) &&
                          <div className="flex-center gap-x-1">
                            <FontAwesomeIcon icon={faCircle} className="text-[#b1ded3] h-2 w-2" />
                            <span className="">ว่าง</span>
                          </div>
                        }
                      </div>
                    })}
                  </div>
                </div>
              </div>
            </div>
            {dateSelected &&
              <div className="px-3 sm:px-11 pt-6 pb-9">
                <div className="flex-grow flex justify-between items-baseline px-3 sm:px-9 py-1.5 border-[1px] border-[#797879] border-dashed rounded-full bg-[#e8e8e9]">
                  <div className="flex items-baseline gap-x-3">
                    <div className="">
                      <FontAwesomeIcon
                        icon={faCalendarDay}
                        className="text-xl sm:text-2xl"
                      />
                    </div>
                    <div className="text-xl sm:text-2xl">{ConvertDate(dateSelected, 'ddd, DD MMMM YYYY')}</div>
                  </div>
                  {periodSelected == 1 && <div>
                    <span className="text-xl sm:text-2xl mr-6">ช่วงเช้า</span>
                    <span className="text-xl sm:text-2xl">09.00 - 12.00 น.</span>
                  </div>
                  }
                  {periodSelected == 2 && <div>
                    <span className="text-xl sm:text-2xl mr-6">ช่วงบ่าย</span>
                    <span className="text-xl sm:text-2xl">13:00-17:00 น.</span>
                  </div>
                  }
                  {periodSelected == 3 && <div>
                    <span className="text-xl sm:text-2xl">ช่วงเวลาใดก็ได้</span>
                  </div>
                  }
                </div>
              </div>
            }

          </div>
          <div className="bg-white rounded-lg mb-9 mx-2 px-5 sm:px-12 pt-8 pb-12  shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
            <div className="text-3xl mb-3">รายการแจ้งซ่อม</div>
            <div className="rounded-[25px] pl-3 pr-4 sm:pl-[45px] sm:pr-[25px] pb-5 border-2 border-[#797879] border-dashed bg-[#f9fafb] divide-y-2 divide-dashed divide-[#797879]">
              {listRepair.map((item, index) => {
                return (
                  <div className="py-3 sm:py-6">
                    <div className="text-2xl sm:text-3xl">รายการที่ {index + 1}</div>
                    <div className="flex gap-x-5">
                      <div className="text-xl sm:text-2xl px-5 bg-black text-white rounded-lg">
                        {catalogs.find(x => x.id == item.repair)?.catalogname}
                      </div>
                      <div className="text-xl sm:text-2xl">
                        {subcatalogs.find(x => x.id == item.jobType)?.subcatalogName}
                      </div>
                    </div>
                  </div>
                )
              })
              }
            </div>
          </div>
          <div className="mx-2 mt-5 mb-12 flex justify-between">
            <div onClick={() => setstep(1)} className="btn-gray-square flex justify-between items-center w-44 sm:w-auto">
              <div className="px-3 py-1.5">
                <div className="flex justify-center items-center bg-[#474747] rounded-full">
                  <FontAwesomeIcon
                    icon={faCircleChevronLeft}
                    className="text-[#9A9A9A]"
                  />
                </div>
              </div>
              <div className="flex-grow flex justify-center items-center py-2 text-lg">
                กลับ
              </div>
            </div>
            {/* <Link to={`/repair-detail-summary?request=${request}`} className="w-44 sm:w-auto"> */}
            <button type="submit" className="btn-black-square flex justify-center items-center py-2 text-lg">
              ยืนยันการนัดหมาย
            </button>
            {/* </Link> */}
          </div>
        </form>}


      </div>
    </div >
  );
};




export default RepairDetailUtils;
