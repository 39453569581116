import { useState } from "react"
import logo from "./logo.svg";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/home";
import SignIn from "./pages/signin";
import Repair from "./pages/repair";
import Complain from "./pages/complain";
import RepairDetail from "./pages/repair/RepairDetail";
import RepairDetail2 from "./pages/repair/RepairDetail2";
import RepairSummary from "./pages/repair/RepairSummary";
import Suggestion from "./pages/suggestion";
import History from "./pages/history/History";
import RepairStatus from "./pages/history/RepairStatus";
import CancleRepair from "./pages/history/CancleRepair";
import PostPoneRepair from "./pages/history/PostPoneRepair";
import NavbarMobile from "./components/NavbarMobile";
import SideBar from "./components/SideBar";
import RepairUtils from "./pages/repair/Utils";
import RepairDetailUtils from "./pages/repair/RepairDetailUtils";
function App() {

  const [sidebar, setSidebar] = useState(false)

  return (
    <>
      <Router>
        <div className="App flex sm:block">
          <div className={`flex-shrink-0 w-[80%] bg-black ${sidebar ? 'w-[80%]':'w-0'} duration-300 sm:hidden`}>
            <SideBar sidebar={sidebar} setSidebar={setSidebar} />
          </div>
          <div>
            <Header sidebar={sidebar} setSidebar={setSidebar} />
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Home}></Route>
              <Route path="/signin" component={SignIn}></Route>
              <Route path="/repair" component={Repair}></Route>
              <Route path="/repairUtils" component={RepairUtils}></Route>
              <Route path="/repair-detail" component={RepairDetail}></Route>
              <Route path="/repair-detail-utils" component={RepairDetailUtils}></Route>
              <Route path="/repair-detail-2" component={RepairDetail2}></Route>
              <Route
                path="/repair-detail-summary"
                component={RepairSummary}
              ></Route>
              <Route path="/repair-status/:id" component={RepairStatus}></Route>
              <Route path="/repair-cancel/:id" component={CancleRepair}></Route>
              <Route
                path="/repair-postpone/:id"
                component={PostPoneRepair}
              ></Route>
              <Route path="/complain" component={Complain}></Route>
              <Route path="/suggestion" component={Suggestion}></Route>
              <Route path="/history" component={History}></Route>
            </Switch>
            <Footer />
            <NavbarMobile />
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
