import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faSpinner,
  faTools,
  faCircleCheck,
  faStar,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import ModalReview from "../../components/ModalReview";
import CSwlAlert from "../../utils/alert";
import ApiTransaction from "../../api/ApiTransaction";
import { ConvertDate, getPeriod } from "../../utils";

const RepairStatus = () => {
  const history = useHistory();
  const params = useParams();
  const id = params.id;
  const location = useLocation();
  const maRef = new URLSearchParams(location.search).get("request")
  const [repair, setRepair] = useState("ระบบไฟ");
  const [order, setOrder] = useState('');
  const [modal, setModal] = useState(false);

  useEffect(() => {
    CSwlAlert.SwalLoad();
    const promise1 = getTransaction();
    Promise.all([promise1]).then((result) => {
      CSwlAlert.SwalClose();
    })
  }, []);

  const getTransaction = async () => {
    try {
      const response = await ApiTransaction.getRef(id);
      if (response.status == 200) {
        const { data } = response.data;
        console.log(data)
        setOrder(data);
      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message }).then((result) => {
        history.go(-1)
      });
    }

  }

  const sendRating = async (model) => {
    try {
      const response = await ApiTransaction.updateRating({
        ...model,
        maRef: id
      });
      if (response.status == 200) {
        CSwlAlert.SwalSuccess("", { html: "ให้คะแนนสำเร็จ" }).then((result) => {
          history.push('/history');
        })
      }
    } catch (error) {
      const { data } = error.response;
      CSwlAlert.SwalErr({ description: data.message })
    }
  }

  return (
    <div>
      <div className="bg-white w-100 hidden sm:flex items-center flex-col py-4">
        <span className="text-3xl">เลขที่แจ้งซ่อม : {id}</span>
        <div className="gap-4 flex">
          <Link to="/">
            <span className="text-xl">หน้าหลัก</span>
          </Link>
          <span className="text-xl">•</span>
          <Link to="/history">
            <span className="text-xl">ประวัติรายการแจ้งซ่อม</span>
          </Link>
          <span className="text-xl">•</span>
          <span className="text-xl">เลขที่แจ้งซ่อม : {id}</span>
        </div>
      </div>
      <div className="container max-w-3xl mx-auto py-10">
        <div className="bg-white rounded-lg sm:mb-9 mx-2 divide-y-[12px] divide-[#f9fafb] shadow-[3.6px_10.4px_30px_0_rgba(228,231,234,0.46)]">
          <div className="flex justify-between px-3 sm:px-12 py-6">
            <div className="flex items-end sm:items-baseline gap-x-2 sm:gap-x-3">
              <div className="text-xl sm:text-3xl">ประเภท</div>
              <div className="text-xl sm:text-3xl text-white bg-black px-3 sm:px-6 rounded-full">
                {"แจ้งซ่อม"}
              </div>
              <div className="text-sm sm:text-2xl">
                เลขที่แจ้งซ่อม : {id}
              </div>
            </div>
            {order?.main?.mastatus === "CP" ? (
              <div className="flex gap-x-3 justify-center items-center sm:px-4 rounded-lg bg-green-500 w-[115px] sm:w-[200px]">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-xl sm:text-2xl text-gray bg-black rounded-full"
                />
                <div className="text-lg sm:text-2xl">เสร็จแล้ว</div>
              </div>
            ) : order?.main?.mastatus === "FS" ? (
              <div className="flex gap-x-3 justify-center items-center sm:px-4 rounded-lg bg-yellow w-[115px] sm:w-[200px]">
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="text-xl sm:text-2xl text-gray"
                />
                <div className="text-lg sm:text-2xl">รอตรวจรับงาน</div>
              </div>
            ) : (
              (order?.main?.mastatus === "WA" || order?.main?.mastatus === "AF") && (
                <div className="flex justify-center items-center gap-x-3 sm:px-4 rounded-lg bg-[#fbf2c9] w-[115px] sm:w-[200px]">
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="text-lg sm:text-2xl text-gray"
                  />
                  <div className="text-lg sm:text-2xl">{order.main.madate ? "รอดำเนินการ" : "รอนัดหมาย"}</div>
                </div>
              )
            )}
          </div>
          <div className="mb-9 px-5 sm:px-12 pt-8 sm:pb-12">
            <div className="text-2xl sm:text-3xl mb-3">รายการแจ้งซ่อม</div>
            <div className="rounded-[25px] pl-4 pr-3 sm:pl-[45px] sm:pr-[25px] pb-5 border-2 border-[#797879] border-dashed bg-[#f9fafb] divide-y-2 divide-dashed divide-[#797879]">
              {
                order.items && <>
                  {order.items.map((item, index) => {
                    console.log('history', order)
                    let status = ""
                    if (order?.main?.mastatus == "CP" || order?.main?.mastatus == "WR") {
                      if (item.maitemStatus != "CP") {
                        status = "(เปิดใบใหม่)"
                      }
                    }
                    return <div key={'list' + index} className="py-6">
                      <div className="text-2xl sm:text-3xl">{`รายการที่ ${index + 1} ${status}`}</div>
                      <div className="flex gap-x-3 sm:gap-x-8 items-center">
                        <img src={item.images?.length > 0 && process.env.REACT_APP_IMAGE + item.images[0].pathFile} className="flex-shrink-0 w-[80px] h-[80px] sm:w-[121px] sm:h-[121px] rounded-lg border-2 border-gray" />
                        <div className="flex flex-col gap-y-3 mt-5 sm:mt-0">
                          <div className="flex gap-x-5">
                            <div className="text-xl sm:text-2xl px-5 bg-black text-white rounded-lg">
                              {item.catalogName}
                            </div>
                            <div className="text-xl sm:text-2xl">{item.subCatalogName}</div>
                          </div>
                          {
                            order.main.madate && <div className="flex sm:items-center gap-x-3">
                              <div className="">
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  className="text-xl sm:text-2xl"
                                />
                              </div>
                              <div className="sm:text-xl">
                                {`วันเวลาที่รับบริการ ${ConvertDate(order.main.madate, 'วันddd ที่ DD MMM yyyy', 'th')} (${getPeriod(order.main.period)})`}
                              </div>
                            </div>
                          }

                        </div>
                      </div>
                      {
                        (item.images && item.images.filter(x => x.type == "REVIEW").length > 0) &&
                        <div className="flex flex-col mt-2">
                          <div className="text-2xl sm:text-3xl">{`รูปภาพจาก Foreman`}</div>
                          <div className="flex flex-wrap gap-2">
                            {
                              item.images?.length > 0 && item.images.filter(x => x.type == "REVIEW").map((item, index) => {
                                return <img src={process.env.REACT_APP_IMAGE + item.pathFile} className="flex-shrink-0 w-[80px] h-[80px] sm:w-[121px] sm:h-[121px] rounded-lg border-2 border-gray" />
                              })
                            }
                          </div>
                        </div>
                      }

                    </div>
                  })}
                </>
              }
            </div>
            <div className="text-3xl mt-8 mb-3">สถานที่</div>
            {order.user &&
              <div className="flex items-center gap-x-3 sm:gap-x-5">
                {/* <div className="flex-shrink-0 w-[60px] h-[60px] rounded-full border-2 border-gray"></div> */}
                <div>
                  <div className="sm:text-2xl flex gap-x-2">
                    <span>{`${order.user.firstname} ${order.user.lastname}`}</span>
                    <span className="text-gray">Tel : {order.user.mobileNumber}</span>
                  </div>
                  <div className="sm:text-2xl">
                    {order.user.addressNo}
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="flex justify-between sm:py-6 py-3 px-5 sm:px-12">
            {order?.main?.mastatus === "WA" || order?.main?.mastatus === "AF" ? (
              <Link to={`/repair-cancel/${id}`}>
                <div className="btn-gray-square flex justify-center text-xl py-2 w-[170px] sm:w-auto">
                  ขอยกเลิกบริการ
                </div>
              </Link>
            ) : order?.main?.mastatus === "CP" ? (
              <div className="flex sm:gap-x-3 justify-center items-center text-xl py-2 w-[170px] sm:w-auto">
                <span className="sm:text-2xl mr-2 sm:mr-0">คะแนน</span>
                {[1, 2, 3, 4].map((num, index) => {
                  return num <= order?.main?.rating ? (
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-xl sm:text-2xl text-yellow"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-xl sm:text-2xl text-gray-light opacity-60"
                    />
                  );
                })}
              </div>
            ) : (
              repair.status === "wait_inspect" && (
                <Link to={`/repair-postpone/${id}`}>
                  <div className="btn-yellow-square flex justify-center items-center gap-x-3 text-xl py-2 w-[170px] sm:w-auto">
                    <FontAwesomeIcon
                      icon={faTools}
                      className="text-xl sm:text-2xl text-black"
                    />
                    แจ้งขยายเวลาซ่อม
                  </div>
                </Link>
              )
            )}
            {(order?.main?.mastatus === "WA" || order?.main?.mastatus === "AF") && order?.main?.madate ? (
              <Link to={`/repair-postpone/${id}`}>
                <div className="btn-yellow-square flex justify-center text-xl py-2 w-[170px] sm:w-auto">
                  ขอเลื่อนบริการ
                </div>
              </Link>
            ) : order?.main?.mastatus === "CP" ? (
              <div className="flex items-center sm:text-xl gap-x-3">
                <FontAwesomeIcon
                  icon={faUserAlt}
                  className="text-xl sm:text-2xl text-gray"
                />
                <span className="text-gray">ทีมงาน :</span> อารียาแฟมมิลี่ 025
              </div>
            ) : order?.main?.mastatus === "WR" ? (
              <div className="flex w-full justify-end">
                <div
                  className="btn-gray-square !bg-[#efefef] flex justify-center items-center gap-x-3 text-xl py-2 w-[170px] sm:w-auto"
                  onClick={() => setModal(true)}
                >
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-yellow"
                  />
                  ให้คะแนน
                </div>
              </div>
            ) : (
              repair.status === "wait_inspect" && (
                <div
                  className="btn-green-square flex justify-center items-center gap-x-3 text-xl py-2 w-[170px] sm:w-auto"
                  onClick={() => setModal(true)}
                >
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="text-xl sm:text-2xl text-gray bg-black rounded-full"
                  />
                  ตรวจรับงาน
                </div>
              )
            )}
          </div>
        </div>
        <div className="hidden sm:block text-center mb-[40px] mx-2">
          <div className="text-xl">
            เมื่อคุณยืนยันการนัดหมายแล้ว ระบบจะทำการบันทึกรายการไว้
            คุณสามารถดูบันทึกรายการทั้งหมดได้ที่ “ประวัติรายการ”
          </div>
          <div className="text-xl">
            อย่าลืมรับโทรศัพท์จากทีมงานนะคะ ,ขอบพระคุณที่ใช้บริการ Areeya Family
            ค่ะ
          </div>
        </div>
      </div>

      {modal && <ModalReview setModal={setModal} repair={repair} onClick={sendRating} />}
    </div>
  );
};

export default RepairStatus;
