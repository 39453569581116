import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ApiAuth from "../../api/ApiAuth";
import ApiConfig from "../../api/ApiConfig";
import ApiUser from "../../api/ApiUser";
import CSwlAlert from "../../utils/alert";
import { setLocalStorage } from "../../utils/localStorage";
import Select from 'react-select'
const SignIn = () => {
    const navigate = useHistory();
    const dispatch = useDispatch();
    const [projects, setprojects] = useState([]);
    const [state, setState] = useState({
        username: "user_somchai",
        email: "somchai@hotmail.com",
        zone: "",
        project: "",
        addressNo: "",
        phoneNumber: ""
    });

    const login = async (e) => {
        CSwlAlert.SwalClose();
        e.preventDefault();
        try {
            const response = await ApiAuth.login(state);
            if (response.status == 200) {
                CSwlAlert.SwalClose();
                // setstate({ ...state, adminLogin: false, userList: [] })
                setLocalStorage("token", response.data.token);
                const responseUser = await ApiUser.getProfile();
                if (responseUser.status == 200) {
                    const nowDate = moment(new Date, "DD/MM/YYYY");
                    const warranteedate = moment(new Date(responseUser.data.users.warranteedate), "DD/MM/YYYY");
                    if (warranteedate.isBefore(nowDate, 'date')) {
                        responseUser.data.users.is_verify = false;
                        const confirmed = await CSwlAlert.SwalConfirm("ประกันหมดอายุ ใช้งานได้เฉพาะส่วนสาธารณูปโภค ต้องการดำเนินการต่อหรือไม่ ?");
                        if (confirmed.isConfirmed) {
                            dispatch({ type: "set", user: responseUser.data.users, token: response.data.token, messageError: "" });
                            navigate.push('/');
                        } else {
                            setLocalStorage("token", "");
                        }
                    } else {
                        responseUser.data.users.is_verify = true;
                        dispatch({ type: "set", user: responseUser.data.users, token: response.data.token, messageError: "" });
                        navigate.push('/');
                    }
                    //if (moment(responseUser.data.users.warranteedate).toDate() == moment().toDate())

                }
                // CSwl.SwalClose();
            } else if (response.status == 404) {
                CSwlAlert.SwalErr({ description: "xxx" })
            }
        } catch (error) {
            const { data } = error.response;
            CSwlAlert.SwalErr({ description: data.message })
        }
    }

    useEffect(() => {
        getProjects();
    }, []);

    const getProjects = async () => {
        const response = await ApiConfig.getProjects();
        if (response.status == 200) {
            const { data } = response.data;
            setprojects(data.map(x => {
                return { value: x.projectCode, label: x.projectName }
            }));
        }
    }

    return <div className="flex flex-col sm:flex-row">
        <div className="sm:flex-1 h-32 sm:py-0 sm:h-auto flex bg-green-light"></div>
        <form className="flex-1" onSubmit={login}>
            <div className="flex-1 bg-black-200 text-white p-5 flex-col flex min-h-[300px]">
                <span className="text-2xl font-semibold">เข้าสู่ระบบ Areeya Family</span>
                <span className="text-lg">หมดกังวลเรื่องการแจ้งซ่อม อัพเดตข้อมูลข่าวสาร จัดการเรื่องบ้านได้ง่าย ครบ จบในเว็บเดียว</span>
                {/* <div className="mt-5 flex flex-col">
                    <span className="text-xl required">ชื่อผู้ใช้</span>
                    <input onChange={(e) => {
                        setState({ ...state, username: e.target.value })
                    }} className="form-control text-xl" value={state.username} required />
                </div> */}
                {/* <div className="mt-5 flex flex-col">
                    <span className="text-xl">อีเมล</span>
                    <input onChange={(e) => {
                        setState({ ...state, email: e.target.value })
                    }} className="form-control text-xl" value={state.email} />
                </div> */}
                {/* <div className="mt-5 flex flex-col">
                    <span className="text-xl">เขตโครงการ</span>
                    <input onChange={(e) => {
                        setState({ ...state, zone: e.target.value })
                    }} className="form-control text-xl" value={state.zone} />
                </div> */}
                <div className="mt-5 flex gap-4">
                    <div className="flex-2 flex flex-col">
                        <span className="text-xl">โครงการ</span>
                        {/* <input required onChange={(e) => {
                            setState({ ...state, project: e.target.value })
                        }} className="form-control text-xl" /> */}
                        <Select onChange={(e) => {
                            setState({ ...state, project: e?.value ?? "" })
                        }}
                            className="react-select w-full text-xl"
                            classNamePrefix="react-select"
                            options={projects}
                        />
                    </div>
                    <div className="flex-1 flex flex-col">
                        <span className="text-xl">บ้านเลขที่</span>
                        <input required onChange={(e) => {
                            setState({ ...state, addressNo: e.target.value })
                        }} className="form-control text-xl" />
                    </div>
                </div>
                {/* <div className="mt-5 flex flex-col">
                    <span className="text-xl">เบอร์ที่ให้ติดต่อกลับ</span>
                    <input onChange={(e) => {
                        setState({ ...state, phoneNumber: e.target.value })
                    }} className="form-control text-xl" />
                </div> */}
                <div className="mt-10">
                    <button type="submit" className="btn-green text-black sm:w-40 text-xl h-12 flex items-center justify-center rounded">เข้าสู่ระบบ</button>
                </div>
            </div>
        </form>
    </div>
}

export default SignIn;