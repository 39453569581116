import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RepairComponentMenu from "./components/menu";
import ApiComplain from "../../api/ApiComplain";
import ApiUser from "../../api/ApiUser"
import CSwlAlert from "../../utils/alert";

const Complain = () => {

    const [files, setFiles] = useState([]);
    const [topics, setTopics] = useState([])
    const [topicSelected, setTopicSelected] = useState(1)
    const [userProfile, setUserProfile] = useState({})
    const [desc, setDecs] = useState("")

    useEffect(() => {
        getTopicComplain()
        getProfileUser()
    }, [])

    useEffect(() => {
        console.log(files)
    }, [files])

    const getProfileUser = async () => {
        try {
            const response = await ApiUser.getProfile()
            if (response.status === 200) {
                setUserProfile(response.data.users)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getTopicComplain = async () => {
        try {
            const response = await ApiComplain.getTopicComplain()
            if (response.status === 200) {
                setTopics(response.data.data)
            }
        } catch (error) {
            console.log(error.response);
        }
    }

    const insertComplain = async (e) => {
        e.preventDefault();
        // CSwlAlert.SwalLoad();
        const formData = new FormData();
        formData.append('userId', userProfile.id)
        formData.append('complainId', topicSelected)
        formData.append('complainDecs', desc)
        files.forEach(file => {
            formData.append('Image', file)
        });
        try {
            const response = await ApiComplain.InsertComplain(formData)
            if (response.status === 200) {
                await CSwlAlert.SwalSuccess("", { html: 'ส่งการร้องเรียนเรียบร้อยแล้ว' })
                setFiles([])
                setDecs("")
                setTopicSelected(1)
            } else if (response.status === 404) {
                CSwlAlert.SwalErr({ status: response.status })
            }
        } catch (error) {
            const { data } = error.response;
            CSwlAlert.SwalErr({ description: data.message })
        }
    }

    return <>
        <div className="bg-white w-100 hidden sm:flex items-center flex-col py-4">
            <span className="text-3xl">แจ้งเรื่องร้องเรียน</span>
            <div className="gap-4 flex">
                <Link to="/">
                    <span className="text-xl">หน้าหลัก</span>
                </Link>
                <span className="text-xl">•</span>
                <span className="text-xl">แจ้งเรื่องร้องเรียน</span>
            </div>
        </div>
        <form onSubmit={(e) => insertComplain(e)}>
            <div className="container max-w-2xl mx-auto py-10">
                <div className="bg-white p-5 rounded mx-2">
                    <div className="flex flex-col">
                        <span className="text-xl required">หัวข้อเรื่องร้องเรียน</span>
                        <select className="form-control text-xl"
                            onChange={(e) => {
                                setTopicSelected(e.target.value)
                            }}
                            required>
                            <option value="">เลือกหัวข้อร้องเรียน</option>
                            {
                                topics.map((item) => {
                                    return (
                                        <option key={item.id} value={item.id}>{item.complain}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="mt-5 flex flex-col">
                        <span className="text-xl">รายละเอียด</span>
                        <input placeholder="กรุณากรอกรายละเอียดเรื่องร้องเรียน" className="form-control text-xl" value={desc} onChange={(e) => setDecs(e.target.value)} />
                    </div>
                </div>
                <div className="bg-white p-5 mt-5 rounded mx-2">
                    <div className="flex flex-col">
                        <span className="text-xl">ภาพประกอบ</span>
                        <div className="flex gap-3 overflow-auto">
                            <div className="flex shrink-0 justify-center items-center w-52 mt-3">
                                <label htmlFor="dropzone-file-complain" className="flex flex-col justify-center items-center w-full p-5 bg-gray-50 rounded-lg border-2 border-gray-light border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <div className="flex flex-col justify-center items-center">
                                        <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-light" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                        <p className="mb-2 text-sm text-gray dark:text-gray"><span className="font-semibold">Click to upload</span></p>
                                        <p className="mb-2 text-sm text-gray dark:text-gray">or drag and drop</p>
                                    </div>
                                    <input id="dropzone-file-complain" type="file" name="files" multiple accept="image/png, image/jpeg" className="hidden" onChange={(e) => {
                                        setFiles([...e.target.files])
                                    }} />
                                </label>
                            </div>
                            {
                                files.length > 0 &&
                                files.map((file, index) => {
                                    const url = URL.createObjectURL(file);
                                    return (
                                        <div className="flex shrink-0 justify-center items-center w-52 mt-3 rounded-lg border-2 border-gray-light border-dashed" key={index}>
                                            <label id="dropzone-file-complain" className="flex flex-col justify-center items-center w-full h-full">
                                                <img src={url} alt={file.name} className="object-cover" />
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="mt-5 flex justify-center">
                    <button type="submit" className="btn-black w-60 text-lg flex justify-center" >ยืนยันข้อมูล</button>
                </div>
            </div>
        </form>
    </>
}

export default Complain;