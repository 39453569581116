import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiSuggest extends Component {

    static getTopicSuggest = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: "Suggest/Topic",
            method: "get",
        });
        return result;
    };

    static InsertSuggest = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: "Suggest",
            method: "post",
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        });
        return result;
    };

}

export default ApiSuggest;